import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchCategory } from '../../../action/categoryAction'
import { updateCategory } from '../../../action/categoryAction'

class Categorypage extends Component {
    constructor(){
        super()
        this.state={                        
            CategoryName:'Products',
            ProductCount:9,
            Products:[
                {
                    index:1,
                    Name:'',
                    PrimImg:{
                        link:'',
                        alttxt:''
                    },
                    skuImg:[
                        {
                            
                        },
                        
                    ],
                    Maxprice:0,
                    Minprice:0,
                    Marketinglabel:'',
                    productLink:''
                }
            ]
        }
        this.onClick = this.onClick.bind(this);
        this.onUpdatePage = this.onUpdatePage.bind(this);
    }

    onClick(e){
        this.setState({'sort':e.target.value})
        console.log('==> '+ e.target.value)
    }

    onUpdatePage(e){
        e.preventDefault();
        let path = e.target.parentElement.getAttribute('href');
        this.props.updateCategory(path, this.props.history);
    }


    componentDidMount = ()=> {
        this._asyncRequest = fetchCategory(this.props.location).then(
            externalData => {
              this._asyncRequest = null;
              console.log(externalData.data)
              this.setState(externalData.data);
            }
          );
    }

    componentWillUnmount() {
        if (this._asyncRequest) {
          this._asyncRequest.cancel();
        }
    }

    componentWillReceiveProps = nextProps => {
        this.setState(nextProps.cat);
    }


    render() {
        return (
            <div>
                <div className="row justify-content-md-center">
                    <div className="col col-lg-10">
                        <br />
                        <h1 style={{textTransform: "capitalize"}}>products</h1>
                        <br />
                    </div>
                </div>
                <div className="row justify-content-md-center" style={{paddingBottom:'5px'}}>
                    <div className="col col-lg-10" style={{textAlign:"left"}}>
                        {this.state.ProductCount} products found.
                    </div>
                    <div className="col col-lg-3" style={{textAlign:"right"}}>
                        
                    </div>
                </div>  
                    {this.state.Products.sort((a,b)=>a.index>b.index).map((e,i)=>{
                        return <div className="row justify-content-center">
                            <div className="col col-lg-10 col-10">
                                <a href={e.productLink} alttxt={e.Name} style={{color:'black', textDecoration: 'none'}}>
                                    <div className="card row" style={{textAlign:'justify', margin:'15px', boxShadow: '0 4px 8px 0 rgba(28,32,36,.2)', flexDirection:'row'}}>
                                        <div className="col col-sm-4 col-12">
                                            <img src={e.PrimImg.link} alt={e.PrimImg.alttxt} style={{width:'100%'}}/>
                                        </div>
                                        <div className="card-body col col-sm-8 col-12">
                                            <p style={{fontWeight:'bold', textAlign:'center'}}>{e.Name}</p>
                                            {e.IsPirce === true && <span>
                                                <h5 style={{textAlign:'center', fontWeight:'bolder'}}>{e.Maxprice===e.Minprice?"₹"+e.Minprice:"₹"+e.Maxprice+" - ₹"+e.Minprice}</h5>
                                            </span>
                                            }
                                            {e.IsPirce === false && <span>
                                                <h5 style={{textAlign:'center', fontWeight:'bolder'}}>{e.Minprice}</h5>
                                            </span>
                                            }                                            
                                            <p className="card-text d-none d-md-block" style={{fontWeight:'lighter'}}><span dangerouslySetInnerHTML={{ __html: e.Desc }}/></p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div> 
                    })}                                                
            </div>
        )
    }
}

function mapStateToProps(state){
    return {cat:state.cat};
}

export default withRouter(connect(mapStateToProps, {updateCategory})(Categorypage));
